import { useSignals } from '@preact/signals-react/runtime';
import Box from '@weave-mui/box';
import {
  accordionPositions,
  accordionIcons,
  typographyVariants,
  selectVariants,
  tooltipPlacement,
} from '@weave-mui/enums';
import FormControl from '@weave-mui/form-control';
import { Accordion, AccordionSummary, AccordionDetails } from '@weave-mui/material';
import MenuItem from '@weave-mui/menu-item';
import Select from '@weave-mui/select';
import Typography from '@weave-mui/typography';
import React, { RefObject, useState } from 'react';
import i18n from '../../i18n';
import { AiaDdxAuthRequestKeys, AiaDdxImportProjectDataKeys } from '../aia-ddx/const';
import {
  ReportDataSection,
  ReportDataField,
  MISSING_DATA_POINT_VALUE,
  ReportSubmissionUnits,
} from '../types/reporting';
import { DataPointValueField, DataSourceField, FieldName } from './CalculatedFields';
import DataGrid, { GridColDef, GridRowParams, GridRowSelectionModel } from '@weave-mui/data-grid';
import Checkbox from '@weave-mui/checkbox';
import { InfoS } from '@weave-mui/icons-weave';
import TooltipContainer from '../../analysis/components/TooltipContainer/TooltipContainer';
import { InfoTooltipIcon } from '../../shared/InfoTooltipIcon';

export const DataSection: React.FC<{
  isExpanded: boolean;
  section: ReportDataSection<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>;
  reportSubmissionUnits: ReportSubmissionUnits;
}> = ({ section, isExpanded, reportSubmissionUnits }) => {
  useSignals();
  const { LEFT } = accordionPositions;
  const { OPERATOR } = accordionIcons;
  const simpleFields = section.fields.filter((field) => field.isSimpleDisplay);
  const complexFields = section.fields.filter((field) => !field.isSimpleDisplay);

  const [expand, setExpand] = useState(isExpanded);
  const handleExpanded = () => {
    setExpand(!expand);
  };

  const renderDataFields = (
    fields: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>[],
    complex: boolean,
    isMandatory: boolean,
  ) => {
    const gridTemplateColumns = `${!isMandatory ? '20px' : ''} 300px 1fr`;
    return (
      (!complex && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns,
            gridTemplateRows: `repeat(${fields.length}, 25px)`,
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          {fields.map((field) => (
            <>
              {(!isMandatory && (
                <Box>
                  <Checkbox
                    value={field.isRequired.value}
                    onChange={(ev, checked) => {
                      field.isRequired.value = checked;
                    }}
                  />
                </Box>
              )) || <></>}
              <Box>
                {(field.dataCalculation?.dataPointId && (
                  <Typography variant={typographyVariants.BODY_REGULAR}>
                    <FieldName field={field} />
                  </Typography>
                )) || (
                  <Typography variant={typographyVariants.BODY_REGULAR}>{field.name}</Typography>
                )}
              </Box>
              <Box>{renderDataValueField(field)}</Box>
            </>
          ))}
        </Box>
      )) ||
      renderDataTable(fields, isMandatory)
    );
  };

  const renderDataTable = (
    fields: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>[],
    sectionIsMandatory: boolean,
  ) => {
    const columnHeaders: { field: string; headerName: string; editable: boolean }[] = [
      {
        field: 'name',
        headerName: i18n.t('reporting.dataHeadings.name'),
        editable: false,
      },
      {
        field: 'data',
        headerName: i18n.t('reporting.dataHeadings.value'),
        editable: false,
      },
      {
        field: 'dataType',
        headerName: i18n.t('reporting.dataHeadings.dataType'),
        editable: false,
      },
      {
        field: 'dataSourceName',
        headerName: i18n.t('reporting.dataHeadings.dataSource'),
        editable: false,
      },
    ];

    const columns: GridColDef[] = columnHeaders.map(({ field, headerName, editable }) => ({
      field,
      headerName,
      editable,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const field = params.row.source as ReportDataField<
          AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys
        >;

        let valueComponent = <>{params.value}</>;
        if (params.field === 'data') {
          if (field.type === 'text' && field.dataCalculation?.dataPointId) {
            valueComponent = (field.dataCalculation?.dataPointId.value && (
              <DataPointValueField field={field} reportSubmissionUnits={reportSubmissionUnits} />
            )) || <>{MISSING_DATA_POINT_VALUE}</>;
          }
        }

        if (params.field === 'name' && field.dataCalculation?.dataPointId) {
          valueComponent = <FieldName field={field} />;
        }

        if (
          params.field === 'dataSourceName' &&
          field.dataCalculation?.dataPointId &&
          field.dataCalculation?.isUserDefined
        ) {
          return <DataSourceField field={field} />;
        }

        if (params.field === 'dataSourceName') {
          return <TooltipContainer
            showInfoIcon
            infoIconTitle={field.name}
            infoIconDescription={field.description}
            content={valueComponent}
          />;
        }

        return (
          <TooltipContainer
            infoIconTitle={field.name}
            infoIconDescription={field.description}
            content={valueComponent}
          />
        );
      },
    }));

    const rows = fields.map((field, id) => ({
      id: id.toString(),
      name: field.name,
      data: field.data.value,
      dataType:
        (field.dataType && i18n.t(`reporting.dataTypes.${field.dataType}`)) ||
        MISSING_DATA_POINT_VALUE,
      dataSourceName: field.dataSourceName.value,
      source: field,
    }));

    return (
      <DataGrid
        sx={{
          '& .MuiDataGrid-cell': {
            overflow: 'hidden !important',
            whiteSpace: 'nowrap !important',
            textOverflow: 'ellipsis !important',
          },
          '.MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
          '.MuiDataGrid-row.Mui-selected > div::before': {
            backgroundColor: ' transparent !important',
          },
        }}
        checkboxSelection={!sectionIsMandatory}
        rowHeight={25}
        onRowSelectionModelChange={(params: GridRowSelectionModel, details) => {
          rows.forEach((row) => {
            const field = row.source as ReportDataField<
              AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys
            >;
            field.isRequired.value = params.includes(row.id);
          });
        }}
        disableAutosize={true}
        disableMultipleColumnsSorting
        rows={rows}
        disableColumnReorder={true}
        columns={columns}
        isRowSelectable={(params: GridRowParams) => {
          const field = params.row.source as ReportDataField<
            AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys
          >;

          // block submission of missing user defined fields
          if (field.dataCalculation?.isUserDefined) {
            return field.dataCalculation.dataPointId.value !== '';
          }

          return true;
        }}
      />
    );
  };

  const renderDataValueField = (
    field: ReportDataField<AiaDdxAuthRequestKeys | AiaDdxImportProjectDataKeys>,
  ) => {
    if (field.type === 'text') {
      return (
        (field.dataCalculation?.dataPointId && (
          <Typography
            ref={field?.ref && (field.ref as RefObject<HTMLElement>)}
            variant={typographyVariants.BODY_REGULAR}
          >
            <DataPointValueField field={field} reportSubmissionUnits={reportSubmissionUnits} />
          </Typography>
        )) || (
          <Typography
            ref={field?.ref && (field.ref as RefObject<HTMLElement>)}
            variant={typographyVariants.BODY_REGULAR}
          >
            {field.data}
          </Typography>
        )
      );
    }

    if (field.type === 'select') {
      return (
        <FormControl>
          <Select
            ref={field?.ref && (field.ref as RefObject<HTMLInputElement>)}
            disabled={!field.isRequired.value}
            variant={selectVariants.LINE}
            value={field.data}
            displayEmpty
            onChange={(event) => {
              field.data.value = event.target.value.toString();
            }}
            sx={{ minWidth: '350px' }}
            MenuProps={{
              style: {
                zIndex: 15000,
                maxHeight: '350px',
              },
            }}
          >
            {field.options?.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <>
      {(section.subHeading && (
        <Box sx={{ display: 'flex', paddingBottom: '10px' }}>
          <Typography variant={typographyVariants.BODY_BOLD}>{section.subHeading}</Typography>
        </Box>
      )) || <></>}
      <Box sx={{ display: 'flex', paddingBottom: '10px' }}>
        <Accordion
          position={LEFT}
          icon={OPERATOR}
          onChange={handleExpanded}
          expanded={expand}
          sx={{
            width: '100%',
            '& .MuiCollapse-wrapper': {
              margin: '10px 0px 10px 0px',
            },
          }}
        >
          <AccordionSummary sx={{ maxHeight: '20px' }}>
            <Typography>{section.name}</Typography>
            {(section.sectionTooltip && (
              <InfoTooltipIcon
                icon={<InfoS sx={{ marginLeft: '4px', cursor: 'default' }} color="action" />}
                tooltipContent={section.sectionTooltip}
                tooltiPlacement={tooltipPlacement.TOP}
                maxWidth={'280px'}
              />
            )) || <> </>}
          </AccordionSummary>
          <AccordionDetails>
            {renderDataFields(simpleFields, false, section.sectionIsMandatory)}
            {renderDataFields(complexFields, true, section.sectionIsMandatory)}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
