import {createApi} from "@reduxjs/toolkit/query/react";
import {insightApigeeBaseQuery} from "./base-queries";
import {ModelsEcData} from "./api-constants";
import {
  ModelEmbodiedCarbonBreakdownEntity,
  ModelEmbodiedCarbonBreakdownResponseStatus, ModelEmbodiedCarbonBreakdownResult
} from "../../analysis/dashboard.models";

export const embodiedCarbonDataServiceApi = createApi({
  reducerPath: "ecDataApi",
  baseQuery: insightApigeeBaseQuery,
  keepUnusedDataFor: 3600,//
  tagTypes: [ModelsEcData],
  endpoints: (builder) => ({
    getBreakdown: builder.query<ModelEmbodiedCarbonBreakdownResult, string>({
      query: (modelId) => `/v1/model/${modelId}/materials`,
      providesTags: (result, error, modelId) => [{ type: ModelsEcData, modelId }],
      transformResponse: (response: ModelEmbodiedCarbonBreakdownEntity, meta, arg): ModelEmbodiedCarbonBreakdownResult => {
        if (meta.response.status === 202) {
          return {data: undefined, status: ModelEmbodiedCarbonBreakdownResponseStatus.Pending};
        }
        return {data: response, status: ModelEmbodiedCarbonBreakdownResponseStatus.Completed};
      }
    }),
  })
});

export const { useGetBreakdownQuery } = embodiedCarbonDataServiceApi;
