import React, { useCallback, useEffect, useState, useContext } from 'react';
import Tooltip, { TooltipProps } from '@weave-mui/tooltip';
import { tooltipPlacement, tooltipSize } from '@weave-mui/enums';
import { DataGridContext } from '../../EmbodiedCarbon/DataGridContext';
import { useGridApiContext } from '@weave-mui/data-grid';
import { InfoS } from '@weave-mui/icons-weave';
import Box from '@weave-mui/box';
import { InfoTooltipIcon } from '../../../shared/InfoTooltipIcon';

const { MEDIUM } = tooltipSize;
const { BOTTOM } = tooltipPlacement;

export type TooltipCellProps = {
  content: string | JSX.Element;
  placement?: TooltipProps['placement'];
  title?: string | JSX.Element;
  hasIcon?: boolean;
  showInfoIcon?: boolean;
  infoIconDescription?: string;
  infoIconTitle?: string;
};

export const TooltipContainer = ({
  content,
  placement,
  title,
  hasIcon,
  showInfoIcon,
  infoIconDescription,
  infoIconTitle,
}: TooltipCellProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const dataGridContext = useContext(DataGridContext);
  const { isOpenQuickModal } = dataGridContext;

  useEffect(() => {
    if (isOpenQuickModal) {
      setTooltipVisible(false);
    } 
  }, [isOpenQuickModal, isTooltipVisible]);

  const isTruncated = useCallback((e: any) => {
    return (
      e.target.scrollHeight > e.target.clientHeight || e.target.scrollWidth > e.target.clientWidth
    );
  }, []);

  const handleTooltipOpen = useCallback(
    (e: any) => {
      if ((hasIcon || isTruncated(e))) {
        setTooltipVisible(true);
      }
    },
    [isTruncated, hasIcon]
  );

  const handleTooltipClose = useCallback(() => {
    setTooltipVisible(false);
  }, []);

   useEffect(() => {
     apiRef.current.subscribeEvent('scrollPositionChange', () => {
       if (apiRef.current) {
         setTooltipVisible(false);
       }  
     });
   }, []);

  return (
    <span className={(hasIcon || showInfoIcon) && 'containerContentTooltip'}>
      <Tooltip
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
        leaveDelay={500}
        open={isTooltipVisible}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        placement={BOTTOM || placement}
        size={MEDIUM}
        title=''
        description={title ? title : content}
        PopperProps={{
          style: {
            zIndex: 10000,
            maxWidth: '230px',
          },
        }}
      >
        <>
          {hasIcon && !showInfoIcon && <span style={{ flexShrink: 0, marginRight: '8px' }}>{content}</span>}
          {!hasIcon && !showInfoIcon && <span style={{ textOverflow: 'ellipsis', display: 'flow', whiteSpace: 'nowrap' }}>{content}</span>}
          {!hasIcon && showInfoIcon && (
            <Box sx={{ display: 'flex' }}>
              <InfoTooltipIcon
                tooltipTitle={infoIconTitle}
                tooltipContent={infoIconDescription}
                icon={<InfoS color="action" />}
                tooltiPlacement={tooltipPlacement.BOTTOM}
                size={infoIconDescription ? 'medium' : 'small'}
              />
              <span>{content}</span>
            </Box>
          )}
        </>
      </Tooltip>
    </span>
  );
};

export default TooltipContainer;
