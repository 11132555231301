export const unitsDropdownStylesheet = (styles) => {
  return {
    ...styles,
    input: {
      ...styles.input,
      height: '1.5rem',
      fontSize: '12px',
      lineHeight: '16px'
    },
    option: {
      ...styles.option,
      fontSize: '12px',
      lineHeight: '16px'
    },
    menu: {
      ...styles.menu,
      maxHeight: '500%'
    },
    optionCheckWrapper: {
      display: 'none',
    },
  };
};

export const textAreaStyleDescription = {
  width: '100%',
  '& .MuiInputBase-input': {
    height: '4rem !important',
    minHeight: '4rem !important'
  },
  '& .MuiErrorIconWeave-root': {
    display: 'none'
  }
};

export const textAreaStyleTextField = {
  width: '100%',
  '& .MuiErrorIconWeave-root': {
    display: 'none'
  }
};

export const dataPointsModalStyles = {
  zIndex: 10000,
  '& .MuiModalWeave-box': {
    width: '80%',
    height: '95%',
    maxHeight:'calc(100vh - 20px - 20px);'
  },
  '& .MuiModalWeave-content': {
    maxHeight: 'initial',
    height: '100%',
  },
  '& .MuiModalWeave-content-no-actions': {
    padding: '0px',
  },
  '& .MuiModalWeave-content .MuiModalWeave-child': {
    padding: '0px',
    height: '100%',
  },
  '& .MuiModalWeave-content-text': {
    overflow:'inherit',
    margin: '0px',
    padding: '0px'
  },
  '& .MuiModalWeave-wrapper': {
    justifyContent: 'space-between',
  }
};
