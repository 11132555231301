import { CreateMetricRequest, UpdateMetricRequest } from './requests/parameters';
import { CreateFactorRequest, OverrideFactorRequest, UpdateFactorRequest } from './requests/factors';
import {CreateBenchmarkRequest, UpdateBenchmarkRequest} from "./requests/benchmarks";

export enum ParametersTypesEnum {
  METRICS = 'metrics',
  FACTORS = 'factors',
  BENCHMARKS = 'benchmarks'
}

export type ParametersModalType = ParametersTypesEnum.METRICS | ParametersTypesEnum.FACTORS | ParametersTypesEnum.BENCHMARKS;

export type DataPoints = {
  modelData: DataPoint[];
  ecAnalysisData: DataPoint[];
  energyAnalysisData: DataPoint[];
  metricsData: Metric[];
  factorsData: Factor[];
  benchmarksData: Benchmark[];
};

export enum DataPointType {
  Factor,
  AnalysisResult,
  ModelData,
  Metric,
  Benchmark
}

export enum UnitSystem {
  Metric,
  Imperial
}

export interface DataPointCommonBase {
  readonly id: string;
  readonly name: string;
  displayName: string;
  description: string;
  type: DataPointType;
}

export interface DataPointBase extends DataPointCommonBase{
  readonly dataType?: string;
  unit?: string;
  industryStandardUnitId?: string;
  imperialStandardUnitId?: string;
  isGlobal: boolean;
  isHidden?: boolean;
}

export interface AnalysisResult {
  value: number;
  industryStandardValue?: IndustryStandardValue;
  imperialStandardValue?: IndustryStandardValue;
  isLoading: boolean;
  isPartial?: boolean;
  isError?: boolean;
}

export interface FactorValues {
  values: FactorValue[];
}

export interface Factor extends DataPointBase {
  dataPointValue: FactorValues,
  unitSystem?: UnitSystem //used for custom Factors
}

export interface FactorValue {
  name: string;
  value: number;
  isDefault?: boolean;
  imperialStandardValue?: IndustryStandardValue;
  industryStandardValue?: IndustryStandardValue;
}

export interface IndustryStandardValue {
  value: number;
  typeId: string;
}

export type Unit = {
  id: string;
  name: string;
  useSI: boolean;
  symbol: string;
};

export interface ISpec {
  name: string;
  disciplineId?: string;
  applicableUnits?: Record<string, Unit>;
  storageUnitId?: string;
}

export interface Metric extends DataPointBase {
  formula: string;
  breakdownFormulas?: FormulaBreakdownResult[];
  unitSystem?: UnitSystem; //used for custom Metrics
}

export type PostMetricRequest = CreateMetricRequest & { modelId: string }
export type PatchMetricRequest = UpdateMetricRequest & { modelId?: string }
export type PostFactorRequest = CreateFactorRequest & { modelId: string }
export type PatchFactorRequest = UpdateFactorRequest & { modelId: string; }
export type PatchDefaultFactorRequest = OverrideFactorRequest & { modelId: string }
export type PostBenchmarkRequest = CreateBenchmarkRequest & { modelId: string}
export type PatchBenchmarkRequest = UpdateBenchmarkRequest & { modelId: string}

export interface RenameDataPointRequest {
  id: string;
  displayName: string;
  modelId: string;
}

export interface FormulaBreakdownResult {
  expression: string;
  name?: string;
}

export interface DataPoint extends DataPointBase {
  dataPointPath: string;
  dataPointValue: AnalysisResult;
}

export interface Benchmark extends DataPointCommonBase {
  value: number;
  metrics: string[];
}

export type FormulaToken = {
  isParameter: boolean;
  parameter: DataPointBase | string;
  unit: string;
};

export type DataPointValue = {
  id: string;
  name: string;
  description?: string;
  value: string | number | MetricEvaluationResult | FactorValue[];
  breakdown?: FormulaBreakdownItem[];
  unitSymbol: string;
  type: DataPointType;
  isPartialResult?: boolean;
  warningMessage?: string;
  error?: {
    reason: string;
  },
  isImperial: boolean;
  benchmarks?: Benchmark[];
};

export enum EnumEvaluationStatus {
  Success,
  GeneralError,
  EmptyFormula,
  CircularReference,
  DivideByZero,
  SyntaxError
}

export type MetricEvaluationResult = {
  result: number;
  isError: boolean;
  evaluationStatus: EnumEvaluationStatus;
  evaluationError: string;
  arguments: string[];
};

export type FormulaBreakdownItem = {
  name: string;
  value: number | null | undefined;
};

export type FormulaSelectionChangedEvent = {
  formula: string;
  cursorPositionInText: number;
  previousNodes: string;
};

export type FormulaCalculationFunction = (
  formula: string,
  formulaOwnerMetricId: string,
  useImperial?: boolean,
  factorOverrides?: Record<string, number>,
  extractMetricArguments?: boolean
) => MetricEvaluationResult;

export interface DataPointsNotification {
  title: string;
  text: string;
  textPrimaryBn: string;
  textSecondaryBn: string;
}
