import { createApi } from '@reduxjs/toolkit/query/react';
import { QueryOptions, insightDataServiceBaseQuery } from './base-queries';
import { Dashboard, StandardDashboard } from '../../types/layout';
import { dashboardTagTypes, dashboardTagType, POST, DELETE, PUT } from './api-constants';
import i18n from '../../i18n';
import {countCardsInDashboard} from "../../utils/analytics";

const EVENT_TYPE = 'dashboard';

export const dashboardServiceApi = createApi({
  reducerPath: 'dashboardServiceApi',
  baseQuery: insightDataServiceBaseQuery,
  keepUnusedDataFor: 3600,
  tagTypes: dashboardTagTypes,
  endpoints: (builder) => ({
    getDashboards: builder.query<(Dashboard | StandardDashboard)[], void>({
      query: () => `/v1/dashboards`,
      providesTags: () => dashboardTagTypes,
      extraOptions: {
        errorOptions: {
          notificationType: 'fullPage',
          messageOverride: i18n.t('appError.errorGettingDashboards'),
        },
        analyticsOptions: { eventType: EVENT_TYPE, eventName: 'getDashboards' },
      } as QueryOptions,
    }),
    getDashboardById: builder.query<Dashboard, string>({
      query: (dashboardId: string) => `/v1/dashboards/${dashboardId}}`,
      providesTags: (result) => [{ type: dashboardTagType, id: result.dashboardId }],
      extraOptions: {
        errorOptions: { notificationType: 'toast' },
        analyticsOptions: { eventType: EVENT_TYPE, eventName: 'getDashboardById' },
      } as QueryOptions,
    }),
    createDashboard: builder.mutation<Dashboard, Dashboard>({
      query: (body: Dashboard) => ({
        url: `/v1/dashboards`,
        method: POST,
        body,
      }),
      invalidatesTags: dashboardTagTypes,
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorAddingDashboard'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'createDashboard',
          parseResponseMeta: (data: Dashboard) => {
            return countCardsInDashboard(data);
          },
        } as QueryOptions,
      },
    }),
    updateDashboard: builder.mutation<Dashboard, Dashboard>({
      query: (body: Dashboard) => ({
        url: `/v1/dashboards/${body.dashboardId}`,
        method: PUT,
        body,
      }),
      invalidatesTags: dashboardTagTypes,
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorUpdatingDashboard'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'updateDashboard',
          parseResponseMeta: (data: Dashboard) => {
            return countCardsInDashboard(data);
          },
        }
      }
    }),
    deleteDashboard: builder.mutation<void, string>({
      query: (dashboardId: string) => ({
        url: `/v1/dashboards/${dashboardId}`,
        method: DELETE,
      }),
      invalidatesTags: dashboardTagTypes,
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: i18n.t('appError.errorDeletingDashboard'),
        },
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'deleteDashboard',
        },
      },
    }),
  }),
});

export const {
  useGetDashboardsQuery,
  useGetDashboardByIdQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
} = dashboardServiceApi;
