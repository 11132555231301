import React, { ReactElement, useCallback, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';
import Panel from '@adsk/alloy-react-panel';

import Portal from '@adsk/alloy-react-portal';
import { useCardGrid, CardConfig } from '@adsk/wildcard';
import i18n from '../i18n';
import LayoutGridCardPreview from './LayoutGridCardPreview';
import { placeholderImg } from './constants';
import Typography, { typographyVariants } from '@weave-mui/typography';
import Button, { buttonVariants } from '@weave-mui/button';
import { CardConfigExtended } from './utils/layout-setup';
import { layoutGridCardLibraryStyles } from './LayoutGrid.stylesheet';
import { addNotification } from '../state/slice/notifications-slice';
import { useDispatch } from 'react-redux';

const FooterWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
});

const CardsPreviewWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0,
  justifyContent: 'space-between',
});

const PanelHeader = styled.div({
  overflow: 'hidden',
});

const DEFAULT_PANEL_WIDTH = 300;

export type CardLibraryCustomStylesType = {
  Panel?: CSSObject;
  CardsPreviewWrapper?: CSSObject;
  CardPreviewWrapper?: CSSObject;
}

export interface CardLibraryProps {
  /** the number in pixels for the width of the card library panel */
  panelWidth?: number;
  customStyles?: CardLibraryCustomStylesType;
  portalSelector?: string;
}

const LayoutGridCardLibrary: React.FC<CardLibraryProps> = ({
  panelWidth,
  customStyles,
  portalSelector,
}) => {
  const {
    state: { allLayouts: { desktop }, availableCards, cardLibraryOpen },
    addCards,
    toggleCardLibrary,
  } = useCardGrid();
  const [
    selectedCards,
    setSelectedCards,
  ] = useState<{ [key: string]: CardConfigExtended }>({});
  const dispatch = useDispatch();
  const selectedCardsValues = Object.values(selectedCards);
  const [searchValue, setSearchValue] = useState('');
  const [panelOpened, setPanelOpened] = useState(false);
  const lowerCaseSearchValue = searchValue.toLowerCase();
  const addButtonText = selectedCardsValues.length > 1 ? 'PLURAL' : 'SINGULAR';

  const hoverObserverRef = useRef<Record<string, Function>>({});

  /**
   *
   * @param cardId cardId comopnent to be registered
   * @param fn function that will be reported with the hover changes, the function should accept an string with the cardId
   */
  const registerHoverListener = useCallback((cardId: string, fn: Function) => {
    hoverObserverRef.current[cardId] = fn;
  }, []);

  /**
   *
   * @param current current card id being hovered.
   */
  const emitHoveredCardToListeners = useCallback((current: string) => {
    Object.values(hoverObserverRef.current).forEach((fn) => {
      fn(current);
    });
  }, [])

  const closeCardLibrary = () => {
    toggleCardLibrary();
    setSelectedCards({})
    setSearchValue('');
  };

  const renderFooter = () => (
    <FooterWrapper >
      <Button      
        data-testid='card-library-cancel-button'
        style={{ marginRight: '12px' }}
        onClick={closeCardLibrary}
        variant={buttonVariants.OUTLINED}
      >
        {i18n.t('cardGrid.cardGridLibrary.cancelButton')}
      </Button>
      <Button
        data-testid='card-library-add-button'
        disabled={!selectedCardsValues.length}
        onClick={() => {
          emitHoveredCardToListeners( null );
          addCards(selectedCardsValues);
          closeCardLibrary();
          dispatch(
            addNotification({
              message: i18n.t('notifications.newCardAddedMessage'),
              type: 'success',
              autoHideDuration: 3000,
            })
          )
        }}
        variant={buttonVariants.CONTAINED}
      >
        {i18n.t(`cardGrid.cardGridLibrary.addButton_${addButtonText}`)}
      </Button>
    </FooterWrapper >
  );

  const handleCardClick = (card: CardConfig) => {
    if (card.cardId in selectedCards) {
      const { [card.cardId]: _, ...rest } = selectedCards;

      setSelectedCards(rest);
    } else {
      setSelectedCards({ ...selectedCards, [card.cardId]: card });
    }
  };

  const getPanelWidth = () => {
    if (panelWidth) {
      if (panelWidth < Panel.WIDTHS.SMALL) {
        return Panel.WIDTHS.SMALL;
      }
      return panelWidth;
    }
    return DEFAULT_PANEL_WIDTH;
  };

  return (
    <Portal
      {...(portalSelector ?
        {container: document.querySelector(portalSelector)} :
        {}
      )}
    >
      <Panel
        data-testid='card-library-panel'
        floating={true}
        onClose={toggleCardLibrary}
        open={cardLibraryOpen}
        renderFooter={renderFooter}
        style={{ position: 'fixed', ...customStyles?.Panel, ...layoutGridCardLibraryStyles }}
        title={<PanelHeader>
          <Typography variant={typographyVariants.H3_REGULAR} sx={{
            fontSize: 16,
            color: '#3c3c3c',
            lineHeight: '22px',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            {i18n.t('cardGrid.cardGridLibrary.title')}
          </Typography>
        </PanelHeader>}
        width={getPanelWidth()}
        onAnimationComplete={(panelWidth: {width: number}) => {
          setPanelOpened(panelWidth.width > 0);
        }}
        onMouseLeave={() => { emitHoveredCardToListeners( null ) }}
      >
        <Panel.Body style={{ overflowY: 'auto', overflowX: 'hidden', padding: '0px' }} >
          <CardsPreviewWrapper
            data-testid='cards-preview-wrapper'
            style={{ ...customStyles?.CardsPreviewWrapper }}
          >
            {(availableCards as CardConfigExtended[]).reduce((filteredCards, card, index) => {
              const title = card.title.toLowerCase();

              if (!title.includes(lowerCaseSearchValue)) return filteredCards;

              const { cardId: id, iconUrl } = card;
              const usedCards =
                desktop.filter(({ i }) => i.split('_')[0] === id);
              // For now we assume all cards can be used multiple times
              const inUse = false;

              return [
                ...filteredCards,
                <LayoutGridCardPreview
                  id={id}
                  panelOpened={panelOpened}
                  imgWidth={32}
                  imgHeight={32}
                  imgUrl={iconUrl || placeholderImg}
                  inUse={inUse}
                  isSelected={id in selectedCards}
                  key={`${id}_${index}`}
                  onClick={() => handleCardClick(card)}
                  timesUsed={usedCards.length}
                  title={card.title}
                  subtitle={card.subtitle}
                  tooltip={card.tooltip}
                  style={{ ...customStyles?.CardPreviewWrapper, width: getPanelWidth(), minWidth: getPanelWidth() }}
                  registerHoverListener={registerHoverListener}
                  hoveredCard={emitHoveredCardToListeners}
                />,
              ];
            }, [] as ReactElement[])}
          </CardsPreviewWrapper>
        </Panel.Body>
      </Panel>
    </Portal>
  );
};

export default LayoutGridCardLibrary;
