import Box from '@weave-mui/box';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import i18n from '../../../../i18n';
import { isLongerThanAllowed } from '../../../../analysis/EmbodiedCarbon/AddECDefinition/helper';
import { Benchmark, ParametersModalType } from '../../../../types/metrics';
import {
  DESCRIPTION_INPUT_CHARACTERS_LIMIT,
  TABLE_VALUE_NUMBER_LIMIT,
  containsSpecialCharacters,
} from '../../../utils/dataPointsUtils';
import DataPointsInput from '../../shared/DataPointsInput/DataPointsInput';
import { InputTypeEnum } from '../../../types/input';
import {BENCHMARK_NAME_LIMIT, intOnlyExp} from "../../../utils/benchmarksUtils";

interface BenchmarksBasicDetailsProps {
  selectedBenchmark: Benchmark;
  isReadOnly: boolean;
  modalType: ParametersModalType;
  setSelectedBenchmark: Dispatch<SetStateAction<Benchmark>>;
}

const BenchmarksBasicDetails: React.FC<BenchmarksBasicDetailsProps> = ({ selectedBenchmark, setSelectedBenchmark,modalType, isReadOnly}) => {
  const [benchmarkValue, setBenchmarkValue] = useState<string>('');

  useEffect(() => {
    setBenchmarkValue(selectedBenchmark.value?.toString() ?? '')
  }, [selectedBenchmark?.id]);

  useEffect(() => {
    if (benchmarkValue === '') {
      setSelectedBenchmark((prevState => {
        return {
          ...prevState,
          value: undefined
        }
      }))
    }

    const parsedValue = +benchmarkValue;
    if (!isNaN(parsedValue) && parsedValue.toString() === benchmarkValue) {
      setSelectedBenchmark((prevState) => {
      return {
        ...prevState,
        value: parsedValue
      };
    })
    }
  },[benchmarkValue]);

  const nameChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedBenchmark((prevState) => {
      return {
        ...prevState,
        name: value,
        displayName: value
      };
    })
  }, [setSelectedBenchmark]);

  const descriptionChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedBenchmark((prevState) => {
      return {
        ...prevState,
        description: value
      };
    })
  }, [setSelectedBenchmark]);


  const valueChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setBenchmarkValue('');
    }

    if (!intOnlyExp.test(value)) {
      return;
    }

    setBenchmarkValue(value);
  }, [setBenchmarkValue]);

  const valuePasteHandler = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    let newValue = benchmarkValue + pasteData;

    if (newValue.length > 14) {
      newValue = newValue.slice(0,14);
    }

    if (!intOnlyExp.test(newValue)) {
      return;
    }

    setBenchmarkValue(newValue);
  }, [setBenchmarkValue, benchmarkValue])
  
  return (
    <Box sx={{ height: 'auto', width: '50%'}}>
      <Box data-testid='benchmarks-details-container' sx={
        {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '1.5rem'
        }
      }>
        <DataPointsInput
          name='name'
          placeholder={i18n.t('analysis.dataPoints.placeholders.name')}
          labelName={`${i18n.t('analysis.dataPoints.labels.name')} *`}
          disabled={isReadOnly}
          error={Boolean(isLongerThanAllowed(selectedBenchmark?.name, BENCHMARK_NAME_LIMIT) || containsSpecialCharacters(selectedBenchmark?.name))}
          inputType={InputTypeEnum.text}
          modalType={modalType}
          onChange={nameChangeHandler}
          value={
            selectedBenchmark?.displayName?.trim().length > 0
              ? selectedBenchmark?.displayName
              : selectedBenchmark?.name
          }
        />
        <DataPointsInput
          name='description'
          placeholder={`${i18n.t('analysis.dataPoints.benchmarks.descriptionPlaceholder')}`}
          labelName={`${i18n.t('analysis.dataPoints.labels.description')}`}
          disabled={isReadOnly}
          error={Boolean(isLongerThanAllowed(selectedBenchmark?.description, DESCRIPTION_INPUT_CHARACTERS_LIMIT))}
          inputType={InputTypeEnum.textArea}
          modalType={modalType}
          onChange={descriptionChangeHandler}
          value={selectedBenchmark?.description}
        />
        <Box sx={{my: '2rem'}}>
          <DataPointsInput
            name='value'
            placeholder={i18n.t('analysis.dataPoints.placeholders.value')}
            labelName={`${i18n.t('analysis.dataPoints.labels.value')} *`}
            disabled={isReadOnly}
            error={Boolean(isLongerThanAllowed(`${selectedBenchmark?.value}`, TABLE_VALUE_NUMBER_LIMIT - 1))}
            inputType={InputTypeEnum.number}
            modalType={modalType}
            onChange={valueChangeHandler}
            onPaste={valuePasteHandler}
            value={benchmarkValue}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BenchmarksBasicDetails;
