import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from '../../analysis/dashboard.models';
import { insightBaseQuery } from './base-queries';

export const userDataApi = createApi({
  reducerPath: 'userDataApi',
  baseQuery: insightBaseQuery,
  endpoints: (builder) => ({
    getUserData: builder.query<User, void>({
      query: () => `/userinformation`,
    }),
    getUserEntitlements: builder.query<{ isAuthorized: boolean }, void>({
      query: () => `/entitlements`,
      transformResponse: (_, meta) => ({ isAuthorized: meta.response.status === 200 }),
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
  useGetUserEntitlementsQuery,
  useLazyGetUserEntitlementsQuery,
} = userDataApi;
export const useGetUserDataQueryState = userDataApi.endpoints.getUserData.useQueryState;
