import React, { ReactNode } from 'react';
import Flyout, { anchorPoints } from '@weave-design/flyout';
import classnames from 'classnames';

interface IClassNames {
  componentWrapper: string;
  buttonWrapper: string;
}

interface IMenu {
  open: boolean;
  buttonContent: ReactNode | string;
  children: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onClickOutside?: React.MouseEventHandler<HTMLElement>;
  classNames?: Partial<IClassNames>;
  flyoutProps?: Record<string, any>;
}

export const Menu = ({
  open,
  onClick,
  onClickOutside,
  buttonContent,
  classNames = {},
  flyoutProps,
  children,
}: IMenu) => {
  const componentWrapperClassName = classnames(classNames.componentWrapper);

  const buttonWrapper = classnames(classNames.buttonWrapper, 'menu__button-wrapper');

  function flyoutStylesheet(styles) {
    return {
      ...styles,
      panel: {
        ...styles.panel,
        padding: '0px',
        'min-width': '100px',
      },
    };
  }

  return (
    <div onClick={onClick} className={componentWrapperClassName}>
      <Flyout
        stylesheet={flyoutStylesheet}
        anchorPoint={anchorPoints.BOTTOM_RIGHT}
        open={open}
        content={children}
        onClickOutside={onClickOutside}
        {...flyoutProps}
      >
        <div className={buttonWrapper}>{buttonContent}</div>
      </Flyout>
    </div>
  );
};
