import React, {Dispatch, SetStateAction, useEffect} from 'react';
import Box from '@weave-mui/box';
import Typography from '@weave-mui/typography';
import i18n from '../../../i18n';
import { Select } from '@weave-mui/material';
import MenuItem from '@weave-mui/menu-item';
import { Benchmark } from '../../../types/metrics';
import { MetricCardData } from '../../../types/layout';

export interface MetricBenchmarksSelectorProps {
  cardType: string,
  selectedDataPointId: string,
  benchmarks: Benchmark[],
  currentCardSettings: MetricCardData,
  selectedBenchmarkIds: string[],
  setSelectedBenchmarkIds: React.Dispatch<React.SetStateAction<string[]>>
  setIsDropdownInteractedWith: Dispatch<SetStateAction<boolean>>
}

export const MetricBenchmarksSelector: React.FC<MetricBenchmarksSelectorProps> = ({
    cardType,
    benchmarks,
    selectedDataPointId,
    currentCardSettings,
    selectedBenchmarkIds,
    setSelectedBenchmarkIds,
    setIsDropdownInteractedWith
}) => {

  const setMetricCardData = () => {
    const filteredBenchmarks = benchmarks?.filter(bd =>
      bd.metrics.find(metric => metric === selectedDataPointId))
      .map(bd => bd.id) ?? [];

    if (selectedBenchmarkIds == null ||
      currentCardSettings.parameterId !== selectedDataPointId) {
      setSelectedBenchmarkIds(filteredBenchmarks.length > 0 ? filteredBenchmarks : null);
    } else {
      setSelectedBenchmarkIds((currentCardSettings as MetricCardData)?.selectedBenchmarkIds);
    }
  }

  useEffect(() => {
    setMetricCardData();
  }, [selectedDataPointId]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedBenchmarkIds(value);
    currentCardSettings =  {
      ...currentCardSettings,
      selectedBenchmarkIds: value
    };
    setIsDropdownInteractedWith(true);
  };

  const renderSelectedValues = (selected: string[]) => {
    if (!isMetricEditMode) {
      return i18n.t('cardConfig.noBenchmarksPlaceholder');
    }

    switch (selected.length){
      case 0:
        return <Typography sx={{opacity: '0.5'}}>{i18n.t('cardConfig.selectBenchmarksPlaceholder')}</Typography>;
      case 1:
        return benchmarks.find(bD => bD.id === selected[0])?.displayName;
      default:
        return `${selected.length} ${i18n.t('cardConfig.selectedNumberOfBenchmarks')}`;
    }
  };

  const isMetricEditMode = cardType === 'metric';
  return ( (isMetricEditMode) &&
    <Box
      display={'contents'}
      flexDirection={'column'}
    >
      <Typography sx={{
        marginTop: '1rem'
      }}>
        {i18n.t('cardConfig.benchmarks')}
      </Typography>
      <Select
        disabled={!selectedBenchmarkIds}
        displayEmpty
        multiple
        checkmark
        onChange={handleChange}
        value={selectedBenchmarkIds ?? []}
        renderValue={renderSelectedValues}
        sx={{
          marginTop: '0.5rem'
        }}
      >
        {
          benchmarks.filter(bd =>
            bd.metrics.find( metric => metric === selectedDataPointId))
            .map( benchmark => (
              <MenuItem key={undefined} value={benchmark.id}
                        sx={{
                          backgroundColor: 'transparent !important',
                          border: '1px transparent !important'
                        }}
              >
                {benchmark.displayName}
              </MenuItem>
            ))
        }
      </Select>
    </Box>
  )
}
