import Box from '@weave-mui/box';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import i18n from '../../../../i18n';
import { useDataPoints } from '../../../../layout/hooks/useDataPoints';
import DataPointsTreeView from '../../../../shared/DataPointsTreeView/DataPointsTreeView';
import { FormulaSelectionChangedEvent, Metric } from '../../../../types/metrics';
import {
  parseFormulaForEvaluation,
  removeDataPointFromFormula,
  sanitizeFormulaHtml,
  validateFormulaIndex
} from '../../../utils/formulaUtils';
import { formatNumber } from '../../../../utils/format';
import { CardDataLoadingProgress } from '../../../../layout/cards/base/CardDataLoadingProgress';
import FormulaContainer from './FormulaContainer';
import DataPointsDivider from '../../shared/Divider/DataPointsDivider';
import { useFormulaEvaluator } from '../../../../layout/hooks/useFormulaEvaluator';
import { FormulaEvaluator } from './FormulaEvaluator';

interface FormulaWrapperProps {
  isReadOnly: boolean;
  selectedDataPoint: Metric | undefined;
  originalSelectedDataPoint: Metric | undefined;
  onFormulaUpdate: Dispatch<SetStateAction<string>>;
  imperialUnits: boolean;
  shouldCleanFormula: boolean;
  setShouldCleanFormula: Dispatch<SetStateAction<boolean>>;
  disableSaveButton: (hasFormulaError: boolean) => void;
}

const FormulaWrapper: React.FC<FormulaWrapperProps> = ({
  isReadOnly,
  selectedDataPoint,
  originalSelectedDataPoint,
  onFormulaUpdate,
  imperialUnits,
  shouldCleanFormula,
  setShouldCleanFormula,
  disableSaveButton
}) => {
  const [formula, setFormula] = useState<string>('');
  const [updatedFormula, setUpdatedFormula] = useState<string>('');
  const [result, setResult] = useState<string>('');
  const [isEvalError, setIsEvalError] = useState<boolean>(false);
  const [evalErrorString, setEvalErrorString] = useState<string>('');
  const [indexToInsert, setIndexToInsert] = useState<number>(-1);

  const { data: dataPoints, isLoading: isLoadingDataPoints, isFetching: isFetchingDataPoints } = useDataPoints();
  const { data: formulaEvaluator, isLoading: isLoadingFormulaEvaluator} = useFormulaEvaluator();

  useEffect(() => {
    if (shouldCleanFormula) {
      setFormula(originalSelectedDataPoint.formula);
      setShouldCleanFormula(false);
      return;
    }
    setFormula(selectedDataPoint?.formula);
  }, [selectedDataPoint?.id, shouldCleanFormula]);

  useEffect(() => {
    if(isFetchingDataPoints && updatedFormula) {
      setFormula(updatedFormula);
    }
  }, [isFetchingDataPoints]);

  useEffect(() => {
    // evaluate formula, if necessary
    if (!dataPoints) {
      return;
    }

    evaluateExpression(false);
  }, [updatedFormula, imperialUnits, formula, isReadOnly]);

  const getInsertPosition = ({
    formula,
    cursorPositionInText,
    previousNodes,
  }: FormulaSelectionChangedEvent): number => {
    if (!previousNodes) {
      //place at the start -> concat
      return +cursorPositionInText;
    }
    const parsedFormula = parseFormulaForEvaluation(formula);
    const previousNodesFormula = parseFormulaForEvaluation(previousNodes);
    let indexToInsertTo = previousNodesFormula.length + +cursorPositionInText;
    if(!validateFormulaIndex(parsedFormula, indexToInsertTo)) {
      console.log(`not valid index. fallback`);
      indexToInsertTo = -1; //if we failed to calculate correctly the index fallback to append the token to the end
    }
    return indexToInsertTo;
  };

  const onFormulaUpdated = useCallback((e: React.ChangeEvent<HTMLDivElement>) => {
    const editedFormula = parseFormulaForEvaluation(sanitizeFormulaHtml(e.target.innerHTML));
    if (editedFormula?.length === 0) {
      setIndexToInsert(-1); //if formula is empty reset the index so that we can append
    }
    setUpdatedFormula(editedFormula);
    onFormulaUpdate(editedFormula);
  }, []);

  const addFormulaParameterCb = useCallback(
    (parmId: string) => {
      if (indexToInsert > -1) {
        setFormula(
          [
            updatedFormula.slice(0, indexToInsert),
            `#{${parmId}}`,
            updatedFormula.slice(indexToInsert),
          ].join('')
        );
      } else {
        setFormula(updatedFormula.concat(`#{${parmId}}`));
      }
      setIndexToInsert(-1);
    },
    [formula, indexToInsert, updatedFormula, setFormula, setUpdatedFormula]
  );

  const onDeleteCb = useCallback(
    (value: { index: number; formula: string }) => {
      const currentFormula = parseFormulaForEvaluation(value.formula);
      const newFormula = removeDataPointFromFormula(currentFormula, value.index);
      setFormula(newFormula);
    },
    [formula]
  );

  const onSelectionChangedCb = useCallback((data) => {
    if (!data) {
      setIndexToInsert(-1); //place to the end
      return;
    }
    const indexToInsert = getInsertPosition(data);
    console.log(`### FINAL: ${indexToInsert}`);
    setIndexToInsert(indexToInsert);
  }, []);

  const isDataLoading = isLoadingDataPoints || isLoadingFormulaEvaluator;

  if (isDataLoading || !dataPoints) {
    return <CardDataLoadingProgress />;
  }

  const evaluateExpression = (showResult: boolean) => {
    const formulaResult = formulaEvaluator(isReadOnly ? formula : updatedFormula, selectedDataPoint?.id, imperialUnits);
    const {result, isError, evaluationError} = formulaResult;
    const evaluateResult = formatNumber(result, 2);
    setIsEvalError(isError);
    setEvalErrorString(isError ? evaluationError : '');
    setResult(`${(showResult && !isError) ? evaluateResult : ''}`);
    disableSaveButton(isError);
  }

  const evaluateExpressionAction = () => {
    evaluateExpression(true);
  };

  return (
    <Box
      sx={{
        flex: 1,
        overflow: 'hidden'
      }
    }>
      <DataPointsDivider title={`${i18n.t('analysis.dataPoints.metrics.formula')} *`} dataPointType={selectedDataPoint.type}/>
      <Box sx={
        {
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          border: '1px solid #80808040',
          height: 'calc(100% - 3.5rem)',
          maxHeight: 'calc(100% - 3.5rem)',
          overflow: 'hidden',
          mt: '1rem',
        }}
      >
        <DataPointsTreeView
          dataPoints={dataPoints}
          onClick={addFormulaParameterCb}
          isReadOnly={isReadOnly}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flex: 1,
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <FormulaContainer
              formula={formula}
              onChange={onFormulaUpdated}
              onDelete={onDeleteCb}
              isReadOnly={isReadOnly}
              onSelectionChanged={onSelectionChangedCb}
              imperialUnits={imperialUnits}
            />
          </Box>
          <FormulaEvaluator
            isEvalError={isEvalError}
            evalErrorString={evalErrorString}
            evaluationResult={result}
            evaluateExpressionAction={evaluateExpressionAction}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default FormulaWrapper
