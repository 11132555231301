import {truncateText} from "../analysis/EmbodiedCarbon/Widgets/Charts/utils";
import {fontFamily} from "./chartOptions";

export const benchmarkLineColor: string = '#0696D7';
export const benchmarkChipBackgroundColor: string = '#CDEAF7';
export const chartXValue = 4.5;

const chipWidth = 48;
const chipHeight = 30;
export const benchmarkSeriesStructure = {
  name: '',
  type: 'scatter',
  data: [],
  marker: {
    enabled: false,
  }
};

export const putBenchmarksOnChart = (chart: any) => {
  let benchmarkSeries = chart.series.filter(s => s.type === benchmarkSeriesStructure.type);
  removeBenchmarksFromChart(chart);

  chart.plotlines = [];
  chart.chips = [];
  chart.textLabels = [];
  benchmarkSeries.forEach((series, index) => {
    series.points.forEach(point => {
      if (point.y !== null) {
        chart.plotlines.push(buildBenchmarkLine(chart, point, index).add());
        chart.chips.push(point.chip = buildChip(chart, point, index).add());
        chart.textLabels.push(point.labelText = buildLabel(chart, point, index).add());
      }
    });

  });
}

const buildBenchmarkLine = (chart: any, point: any, index: number): any => {
  return chart.renderer.path(['M', point.plotX + chart.plotLeft, point.plotY + chart.plotTop, 'L', chart.plotLeft, point.plotY + chart.plotTop])
    .attr({
      'stroke-dasharray': '3,3',
      'stroke-width': 1,
      stroke: benchmarkLineColor,
      zIndex: 5 + index
    });
}

const buildChip = (chart, point, index: number) => {
  const chipX = point.plotX + chart.plotLeft;
  const chipY = point.plotY + chart.plotTop;
  return chart.renderer.rect(chipX - chipWidth / 2, chipY - chipHeight / 2, chipWidth, chipHeight, 8)
    .attr({
      fill: benchmarkChipBackgroundColor,
      stroke: benchmarkLineColor,
      'stroke-width': 1,
      zIndex: 6 + index
    })
    .css({
      transform: `scale(1, ${chipHeight / chipWidth})`,
      transformOrigin: `${chipX}px ${chipY}px`
    });
}

const buildLabel = (chart, point, index: number) => {
  const labelText = truncateText(point.series.name, 8);
  // Add text inside the oval shape
  return point.labelText = chart.renderer.text(labelText, point.plotX + chart.plotLeft, point.plotY + chart.plotTop + 4)
    .attr({
      align: 'center',
      zIndex: 7 + index
    })
    .css({
      color: '#3C3C3C',
      fontSize: '10px',
      fontWeight: 'bold',
      fontFamily: fontFamily
    });
}


const removeBenchmarksFromChart = (chart) => {
  const drawObjects = ['textLabels', 'chips', 'plotlines'];
  drawObjects.forEach(object => {
    chart[object]?.forEach(o => o.destroy());
  });
}

export const scaleYAxis = (chart) => {
  let {dataMin, dataMax} = chart.yAxis[0].getExtremes();
  if (dataMin > 0) {
    dataMin = 0;
  }
  if (dataMax < 0) {
    dataMax = 0;
  }
  chart.yAxis[0].setExtremes(dataMin * 1.1, dataMax * 1.1);
}
