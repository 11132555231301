import { createApi } from '@reduxjs/toolkit/query/react';
import { Simulation } from '../../types/simulation';
import { SimulationResultsBreakdown } from '../../types/simulationResultsBreakdown';
import { insightApigeeBaseQuery } from './base-queries';

export const simulationDataApi = createApi({
  reducerPath: 'simulationDataApi',
  baseQuery: insightApigeeBaseQuery,
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    getSimulationsByModel: builder.query<Simulation[], string>({
      query: (modelId) => `/v1/simulations?modelId=${modelId}`
    }),
    getSimulationResultBreakdown: builder.query<SimulationResultsBreakdown, string>({
      query: (simulationUrn) => `/v1/simulations/${simulationUrn}`
    })
  })
});

export const { useGetSimulationsByModelQuery, useGetSimulationResultBreakdownQuery } = simulationDataApi;
