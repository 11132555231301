import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '@weave-mui/breadcrumbs';
import Divider, { dividerOrientations, dividerVariants } from '@weave-mui/divider';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Typography from '@weave-mui/typography';
import { HomeS } from '@weave-mui/icons-weave';
import { useGetModelQuery, useGetProjectQuery } from '../state/api/project-model-data-api';
import DropdownModelSelector from '../model/DropdownModelSelector';
import '../css/new/dropdownModelSelector.css';
import Tooltip, { tooltipPlacement, tooltipSize } from '@weave-mui/tooltip';
import Box from '@weave-mui/box';
import useBreakpoints from './BreakPoints/useBreakPoints';

export type ProjectSettingsModalProps = {
  projectId: string;
  open?: any;
  onCloseClick?: any;
  onApplyClick?: any;
};

const Breadcrumb: React.FC = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs();
  const { modelId } = useParams<{ modelId: string }>();
  const { projectId } = useParams<{ projectId: string }>();
  const { data: model } = useGetModelQuery(modelId, { skip: !modelId });
  const { data: project } = useGetProjectQuery(projectId, { skip: !projectId });
  const { isSm } = useBreakpoints();

  const filteredBreadcrumbs = breadcrumbs.filter((item) => {
    const pathname = item.match.pathname;
    return (
      pathname.endsWith('/layouts') || pathname.endsWith('/projects') || pathname.endsWith('/model')
    );
  });

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const [tooltipEnabledIndex, setTooltipEnabledIndex] = useState(null);
  const showToolTip = (e: any, index) => {
    if (e.target.scrollWidth > e.target.clientWidth) {
      setTooltipEnabledIndex(index);
    }
  };

  return (
    <>
      <Divider
        className="header-divider"
        light={true}
        orientation={dividerOrientations.VERTICAL}
        variant={dividerVariants.FULLWIDTH}
      />
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          minWidth: 'max-content',
          // remove padding from the popover <li>'s because that padding creates
          // a small section where there's a visible hover effect but it doesn't do
          // anything when clicked since the user would be clicking the <li> which doesn't
          // trigger the `handleNavigate` function 
          '& div[role="tooltip"] li': {
            paddingTop: '0',
            paddingBottom: '0',
          },
          // make the tooltip's width fit its content instead of having
          // big unused spaces
          '& div[role="tooltip"] ul': {
            width: 'max-content',
          },
        }}
        maxItems={isSm ? 5 : 2}
        collapsePosition='middle'
      >
        {filteredBreadcrumbs.map((item, index) => {
          const first = index === 0;
          const last = index === filteredBreadcrumbs.length - 1;
          const pathName = item.key;
          let isLayoutPage = false;
          let icon = null;
          let text = null;

          if (pathName.endsWith('/projects')) {
            text = '';
            icon = <HomeS sx={{ marginRight: '1px' }} />;
          } else if (pathName.endsWith('/model')) {
            text = project?.id === projectId ? project?.name : null;
          } else if (pathName.endsWith('/layouts')) {
            text = model?.id === modelId ? model?.name : null;
            isLayoutPage = true;
          } else {
            return null;
          }

          const lastItem = isLayoutPage ? (
            <DropdownModelSelector />
          ) : (
            <Tooltip
              title={''}
              description={
                <Box>
                  <div style={{ marginTop: '1px' }}></div>
                  <Typography variant="caption">{text}</Typography>
                </Box>
              }
              arrow
              placement={tooltipPlacement.BOTTOM}
              size={tooltipSize.MEDIUM}
              enterDelay={500}
              open={tooltipEnabledIndex === index}
              onClose={() => setTooltipEnabledIndex(null)}
            >
              <Typography
                onMouseEnter={(e) => showToolTip(e, index)}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'inline-block !important',
                  maxWidth: '260px',
                  float: 'left',
                  fontWeight: '700',
                }}
              >
                {icon}
                {text}
              </Typography>
            </Tooltip>
          );

          return last ? (
            lastItem
          ) : (
            <div
              onClick={() => handleNavigate(item.key)}
              style={{
                cursor: 'pointer',
                fontWeight: 'bolder',
                width: '100%',
                // if this div will be collapsed into the popover (it's not the first nor last one)
                // that we removed up above, add the padding
                padding: !isSm && !first ? '4px 8px' : '0',
              }}
            >
              <Tooltip
                title=''
                description={
                  <Box>
                    <div style={{ marginTop: '1px' }}></div>
                    <Typography variant="caption">{text}</Typography>
                  </Box>
                }
                arrow
                placement={tooltipPlacement.BOTTOM}
                size={tooltipSize.MEDIUM}
                enterDelay={500}
                open={tooltipEnabledIndex === index}
                onClose={() => setTooltipEnabledIndex(null)}
              >
                <Typography onMouseEnter={(e) => showToolTip(e, index)}>
                  {icon}
                  {text}
                </Typography>
              </Tooltip>
            </div>
          );
        })}
      </Breadcrumbs>
    </>
  );
};

export default Breadcrumb;
