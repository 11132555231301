import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AiaDdxAuthRequest,
  AiaDdxAuthResponse,
  AiaDdxImportProjectDataRequest,
} from '../../reporting/aia-ddx/const';
import { POST } from './api-constants';
import { QueryOptions, insightDymanicUrlBaseQuery } from './base-queries';
import lookup from 'country-code-lookup';

const EVENT_TYPE = 'reporting';

export const reportingDataApi = createApi({
  reducerPath: 'reportingDataApi',
  baseQuery: insightDymanicUrlBaseQuery('https://2030ddx.aia.org​​​​​​​/'),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    aiaDdxAuthenticate: builder.query<AiaDdxAuthResponse, AiaDdxAuthRequest>({
      query: (body: AiaDdxAuthRequest) => ({
        url: `api/v1/authenticate`,
        method: POST,
        body,
      }),
      extraOptions: {
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'aiaddxAuthenticate',
        } as QueryOptions,
      },
    }),
    aiaDdxReport: builder.query<any, AiaDdxImportProjectDataRequest>({
      query: (body: AiaDdxImportProjectDataRequest) => ({
        url: `api/v1/import_project`,
        method: POST,
        body,
      }),
      extraOptions: {
        analyticsOptions: {
          eventType: EVENT_TYPE,
          eventName: 'aiaddxReport',
          recordFailureOperation: true,
          parseRequestMeta: (requestData: AiaDdxImportProjectDataRequest) => ({
            country: lookup.byCountry(requestData?.country)?.iso2 || '',
            zipcode: requestData?.zipcode || '',
          }),
        },
      } as QueryOptions,
    }),
  }),
});

export const { useAiaDdxAuthenticateQuery, useAiaDdxReportQuery } = reportingDataApi;
