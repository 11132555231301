import React, { Dispatch, SetStateAction } from 'react';
import { Metric } from '../../../../types/metrics';
import { Button, buttonVariants } from '@weave-mui/button';
import i18n from '../../../../i18n';
import Box from '@weave-mui/box';
import { CompleteS, ErrorS } from '@weave-mui/icons-weave';
import Typography from '@weave-mui/typography';
import { invalidFormulaRed, validFormulaGreen } from '../../../styles/colorCodes';

interface FormulaEvaluatorProps {
  isEvalError: boolean,
  evalErrorString: string,
  evaluationResult: string
  evaluateExpressionAction: () => void
}

export const FormulaEvaluator: React.FC<FormulaEvaluatorProps> = ({
  isEvalError,
  evalErrorString,
  evaluationResult,
  evaluateExpressionAction
  }) => {
  return (
    <Box sx={
      {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pr: '2rem'
      }
    }>
      <Button
        sx={{ m: 0, p: 0 }}
        variant={buttonVariants.TEXT}
        onClick={evaluateExpressionAction}
        disabled={isEvalError}
      >
        {i18n.t('analysis.dataPoints.labels.evaluate')}
      </Button>
      {
        /* Hide text if there is an error but no explicit user facing string */
        (isEvalError && evalErrorString == '') ? <div/> :
          <Box sx={{
            display: (evaluationResult != "" || isEvalError) ? 'flex' : 'none',
            alignItems: 'right',
            justifyContent: 'flex-end',
            gap: "0.3rem"
          }}>
            { isEvalError ? <ErrorS sx={{ color: invalidFormulaRed }}/> : <CompleteS sx={{ color: validFormulaGreen }}/> }
            <Typography sx={{ color: isEvalError ? invalidFormulaRed : validFormulaGreen }}>
              { isEvalError ? evalErrorString : `Result is ${evaluationResult}` }
            </Typography>
          </Box>
      }
    </Box>
  );
};
